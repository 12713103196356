import { approvalService } from "@/service";
import { reactive } from "vue";

interface Item {
  id: string;
  name: string;
  state: string;
  state_name: string;
  create_date: string;
  user_name: string;
  desc: string;
}

export function fetchList() {
  const state = reactive({
    list: [] as Item[],
    loading: false,
    finished: false
  });

  const onLoad = async (getstate: string, type: string, page: number, pagesize: number) => {
    // 异步更新数据
    try {
      if (page === 1) {
        state.list = [];
        state.finished = false;
      }
      state.loading = true;
      const { data } = await approvalService.approvalMyRelation({
        state: getstate,
        type,
        page,
        pagesize
      });
      state.list.push(...data.data);
      state.loading = false;
      page++;
      if (state.list.length >= data.total) {
        state.finished = true;
      }
    } catch (error) {
      state.loading = false;
      state.finished = true;
    }
  };

  return {
    state,
    onLoad
  };
}
