import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "uf-myLaunch" }
const _hoisted_2 = { class: "uf-myLaunch__top" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_button = _resolveComponent("van-button")
  const _component_list_item = _resolveComponent("list-item")
  const _component_van_empty = _resolveComponent("van-empty")
  const _component_van_list = _resolveComponent("van-list")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.filterBtn, (btn) => {
        return (_openBlock(), _createBlock(_component_van_button, {
          key: btn.label,
          class: { 'uf-myLaunch__button--active': _ctx.active === btn.label },
          onClick: ($event: any) => (_ctx.toggleActive(btn.label)),
          size: "mini"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(btn.value), 1)
          ]),
          _: 2
        }, 1032, ["class", "onClick"]))
      }), 128))
    ]),
    _createVNode(_component_van_list, {
      loading: _ctx.state.loading,
      "onUpdate:loading": _cache[1] || (_cache[1] = ($event: any) => (_ctx.state.loading = $event)),
      finished: _ctx.state.finished,
      "finished-text": "没有更多了",
      onLoad: _ctx.goOnLoad
    }, {
      default: _withCtx(() => [
        (_ctx.state.list.length > 0)
          ? (_openBlock(), _createBlock("div", _hoisted_3, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.state.list, (item) => {
                return (_openBlock(), _createBlock(_component_list_item, {
                  key: item,
                  "user-name": item.user_name,
                  title: item.name,
                  footer: `发起时间：${item.create_date}`,
                  code: item.code,
                  content: item.desc,
                  type: item.state,
                  "type-name": item.state_name,
                  onClick: ($event: any) => (_ctx.toDetail(item.id))
                }, null, 8, ["user-name", "title", "footer", "code", "content", "type", "type-name", "onClick"]))
              }), 128))
            ]))
          : (_openBlock(), _createBlock(_component_van_empty, {
              key: 1,
              image: require('@/assets/empty@2x.png')
            }, null, 8, ["image"]))
      ]),
      _: 1
    }, 8, ["loading", "finished", "onLoad"])
  ]))
}