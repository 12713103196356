<template>
  <div class="uf-myApproval">
    <van-tabs color="#0065FF" title-active-color="#0065FF" v-model:active="active" @click="onClick">
      <van-tab title="我发起的">
        <MyLaunch />
      </van-tab>
      <van-tab title="我审批的">
        <Pending />
      </van-tab>
      <van-tab title="抄送我的">
        <SendToMe />
      </van-tab>
      <van-tab title="草稿箱">
        <Drafts />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import MyLaunch from "@/views/my-approval/MyLaunch.vue";
import Pending from "@/views/my-approval/Pending.vue";
import SendToMe from "@/views/my-approval/SendToMe.vue";
import Drafts from "@/views/my-approval/Drafts.vue";

export default {
  name: "Index",
  created() {
    const { tag } = this.$route.query;
    if (tag !== undefined) {
      this.active = Number(tag);
    }
  },
  data() {
    return {
      active: 0
    };
  },
  methods: {
    onClick(index) {
      this.$router.replace({
        query: {
          tag: index
        }
      });
    }
  },
  components: {
    MyLaunch,
    Pending,
    SendToMe,
    Drafts
  }
};
</script>

<style scoped></style>
