
import { ref, defineComponent } from "vue";
import { fetchList } from "./helper";
import { RouterName } from "@/router";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "SendToMe",
  setup() {
    const postdata = {
      state: "0",
      type: "1",
      page: 0,
      pagesize: 10
    };
    const filterBtn = [
      {
        label: "1",
        value: "待处理"
      },
      {
        label: "2",
        value: "已处理"
      }
    ];
    const router = useRouter();
    const { state, onLoad } = fetchList();
    const active = ref("1");
    // 加载页面数据
    const goOnLoad = () => {
      postdata.page++;
      onLoad(postdata.state, postdata.type, postdata.page, postdata.pagesize);
    };
    // 切换table
    const toggleActive = (activeName: string) => {
      if (postdata.state === activeName) return;
      active.value = activeName;
      postdata.type = activeName;
      postdata.page = 0;
      postdata.page++;
      onLoad(postdata.state, postdata.type, postdata.page, postdata.pagesize);
    };
    // 去详情
    const toDetail = (id: string) => {
      router.push({
        name: RouterName.APPROVAL_DETAIL,
        params: {
          id
        }
      });
    };
    return {
      postdata,
      state,
      onLoad,
      active,
      filterBtn,
      toggleActive,
      toDetail,
      goOnLoad
    };
  }
});
