
import { ref, defineComponent, onBeforeUpdate } from "vue";
import { fetchList } from "./helper";
import { RouterName } from "@/router";
import { useRouter } from "vue-router";
import { Dialog, Toast } from "vant";
import { approvalService } from "@/service";
export default defineComponent({
  name: "Drafts",
  setup() {
    const postdata = {
      state: "0",
      type: "5",
      page: 0,
      pagesize: 10
    };

    const router = useRouter();
    const { state, onLoad } = fetchList();
    // 加载页面数据
    const goOnLoad = () => {
      postdata.page++;
      onLoad(postdata.state, postdata.type, postdata.page, postdata.pagesize);
    };

    const isEdit = ref(false);
    const checked = ref([]);
    const checkboxRefs = ref([]);
    onBeforeUpdate(() => {
      checkboxRefs.value = [];
    });

    // 去详情
    const toDetail = (item: any) => {
      if (item.is_update == "1") {
        Dialog.confirm({
          message: "模板已变更，无法重新发起，是否删除草稿？",
          confirmButtonText: "删除"
        })
          .then(async () => {
            await approvalService.approvalDel(item.id);
            Toast.success("删除成功");
            onLoad(postdata.state, postdata.type, 1, postdata.pagesize);
          })
          .catch(() => {
            // on cancel
          });
        return;
      }
      router.push({
        name: RouterName.APPROVAL_START,
        params: {
          id: item.approval_temp_id
        },
        query: {
          detailId: item.id
        }
      });
    };

    const deleteDrafts = () => {
      Dialog.confirm({
        message: "删除后无法恢复，确认要删除吗？",
        confirmButtonText: "删除"
      })
        .then(async () => {
          await approvalService.approvalDel(checked.value.join(","));
          Toast.success("删除成功");
          onLoad(postdata.state, postdata.type, 1, postdata.pagesize);
        })
        .catch(() => {
          // on cancel
        });
      return;
    };
    return {
      postdata,
      state,
      onLoad,
      toDetail,
      goOnLoad,
      checked,
      checkboxRefs,
      isEdit,
      deleteDrafts
    };
  }
});
